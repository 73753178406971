import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppNavigationComponent } from '@ciphr/core/app-navigation/features';
@Component({
  selector: 'mypay-reporting-root',
  standalone: true,
  imports: [RouterOutlet, AppNavigationComponent],
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent {}
