import { ApplicationConfig, ErrorHandler, importProvidersFrom, isDevMode, LOCALE_ID } from '@angular/core';
import { provideHttpClient } from '@angular/common/http';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { GlobalErrorHandler } from '@ciphr/core/error-handler';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideEffects } from '@ngrx/effects';
import { ChrComponentsModule } from '@ciphr-design-system/angular';
import { HTTP_API_CLIENT, provideHttpApiClient } from '@ciphr/utils/data-adapters';
import { REPORTING_HTTP_CLIENT } from './core/tokens/mypay-reporting-http-client.token';
import { authContextInterceptor } from '@ciphr/core/auth-context/features';
import { MYPAY_HTTP_CLIENT } from './core/tokens/mypay-http-client.token';
import { provideAppConfig } from '@ciphr/core/app-config';
import { provideAppBranding } from '@ciphr/core/app-branding';
import { AuthService, provideAuth } from '@ciphr/core/auth';
import { authConfig } from './auth.config';
import { AUTH_PANEL_SERVICE } from '@ciphr/core/app-navigation/features';
import { AuthContextApiDataAdapterService, provideAuthContextApiDataAdapterProvider } from '@ciphr/core/auth-context/api-data-adapter';
import { provideAuthContextState } from '@ciphr/core/auth-context/state';
import { provideAppPermissions } from '@ciphr/core/app-permissions';
import { AppNavigationApiDataAdapterService, provideAppNavigationApiDataAdapter } from '@ciphr/core/app-navigation/api-data-adapter';
import { provideAppNavigationState } from '@ciphr/core/app-navigation/state';
import { provideProfileApiDataAdapter } from '@ciphr/core/profile/data-adapters';
import { provideStaticTranslation } from '@ciphr/shared/translation';
import { translationConfig } from './translations/translation.config';
import { AVATARS_API_URL } from '@ciphr/shared/avatar';
import { REPORTING_API_CONFIG } from './core/tokens/mypay-reporting-api.token';
import { SSO_HTTP_API_CLIENT } from './core/tokens/sso-http-api-client.token';

const mypayApiConfig = { name: 'mypay', version: 1 };
const mypayReportingApiConfig = { name: 'mypay-reporting', version: 1 };

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    provideRouter(appRoutes),
    { provide: LOCALE_ID, useValue: 'en-GB' },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'dd/MMM/yyyy' } },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },

    // 3rd party providers
    provideStore(),
    provideStoreDevtools({ logOnly: !isDevMode() }),
    provideEffects(),

    // design system
    importProvidersFrom(
      ChrComponentsModule.forRoot({
        components: true,
        defaultTheme: 'ciphr-light',
        iconsUrl: 'assets/icons',
        illustrationsUrl: 'assets/illustrations',
        polyfills: true,
      }),
    ),

    // API client
    provideHttpApiClient(REPORTING_HTTP_CLIENT, mypayReportingApiConfig, [authContextInterceptor]),
    provideHttpApiClient(MYPAY_HTTP_CLIENT, mypayApiConfig, [authContextInterceptor]),
    provideHttpApiClient(SSO_HTTP_API_CLIENT, { name: 'sso', version: 1 }),

    { provide: HTTP_API_CLIENT, useExisting: REPORTING_HTTP_CLIENT },

    // core providers
    provideAppConfig(),
    provideAppBranding(SSO_HTTP_API_CLIENT),
    provideAuth(authConfig, ['/mypay-reporting/api/v1', '/mypay/api/v1'], 'mypay'),
    { provide: AUTH_PANEL_SERVICE, useExisting: AuthService },

    provideAuthContextApiDataAdapterProvider({ apiConfig: mypayApiConfig }),
    provideAuthContextState(AuthContextApiDataAdapterService),

    provideAppPermissions(mypayApiConfig),

    provideAppNavigationApiDataAdapter(mypayApiConfig),
    provideAppNavigationState(AppNavigationApiDataAdapterService),

    provideProfileApiDataAdapter(),
    provideStaticTranslation(translationConfig),

    // value tokens
    { provide: AVATARS_API_URL, useValue: '/static/users-photos/' },
    { provide: REPORTING_API_CONFIG, useValue: mypayReportingApiConfig },
  ],
};
