export const enTranslation = {
  'COMMON.ACTIONS.ADD': 'Add',
  'COMMON.ACTIONS.ACCEPT': 'Accept',
  'COMMON.ACTIONS.AGREE': 'Agree',
  'COMMON.ACTIONS.APPLY': 'Apply',
  'COMMON.ACTIONS.BACK': 'Back',
  'COMMON.ACTIONS.CANCEL': 'Cancel',
  'COMMON.ACTIONS.CREATE': 'Create',
  'COMMON.ACTIONS.CLEAR_ALL': 'Clear all',
  'COMMON.ACTIONS.CLOSE': 'Close',
  'COMMON.ACTIONS.DECLINE': 'Decline',
  'COMMON.ACTIONS.DELETE': 'Delete',
  'COMMON.ACTIONS.DOWNLOAD': 'Download',
  'COMMON.ACTIONS.DUPLICATE': 'Duplicate',
  'COMMON.ACTIONS.EDIT': 'Edit',
  'COMMON.ACTIONS.I_UNDERSTAND': 'I understand',
  'COMMON.ACTIONS.NEXT': 'Next',
  'COMMON.ACTIONS.NO': 'No',
  'COMMON.ACTIONS.REMOVE': 'Remove',
  'COMMON.ACTIONS.SAVE': 'Save',
  'COMMON.ACTIONS.SORT': 'Sort',
  'COMMON.ACTIONS.SUBMIT': 'Submit',
  'COMMON.ACTIONS.USE': 'Use',
  'COMMON.ACTIONS.VIEW_DETAILS': 'View details',
  'COMMON.ARIA.ACTIONS.BACK_BUTTON': 'Back button',
  'COMMON.ARIA.ACTIONS.BULK_BUTTON': 'Bulk actions',
  'COMMON.ARIA.ACTIONS.TOGGLE_ARROW': 'Toggle expansion panel',
  'COMMON.ARIA.INPUTS.AUTOMATIC_SEARCH': 'Search over menu. During typing the search results will be narrowed down to the entered phrase',
  'COMMON.COLLECTIONS.ARIA.BUTTONS.CLOSE': 'Close filters drawer',
  'COMMON.COLLECTIONS.ARIA.BUTTONS.FILTER_HEADER': 'Toggle button for {{filterKey}} filter',
  'COMMON.COLLECTIONS.ARIA.BUTTON.OVERLAY_MENU': 'Button with three dots to toggle overlay menu',
  'COMMON.COLLECTIONS.ARIA.BUTTONS.TOGGLE_DRAWER': 'Toggle filters drawer',
  'COMMON.COLLECTIONS.ARIA.SELECT.PAGE_SIZE': 'Page size',
  'COMMON.COLLECTIONS.EMPTY.NO_RECORDS.DESCRIPTION': 'It appears that there are no records in this table',
  'COMMON.COLLECTIONS.EMPTY.NO_RECORDS.HEADER': 'No records here',
  'COMMON.COLLECTIONS.EMPTY.NO_RESULTS.DESCRIPTION': 'It seems that we didn’t find what you were looking for',
  'COMMON.COLLECTIONS.EMPTY.NO_RESULTS.HEADER': 'No result found',
  'COMMON.COLLECTIONS.FILTERING.APPLIED_FILTERS_NUMBER_BADGE': '{{filtersNumber}} filters applied',
  'COMMON.COLLECTIONS.FILTERING.DRAWER.ACTIONS.CLEAR_ALL': 'Clear all',
  'COMMON.COLLECTIONS.FILTERING.DRAWER.ACTIONS.FILTER': 'Filter',
  'COMMON.COLLECTIONS.FILTERING.DRAWER.HEADER': 'Filters',
  'COMMON.COLLECTIONS.HEADERS.ACTIONS': 'Actions',
  'COMMON.COLLECTIONS.HEADERS.SELECT': 'Select',
  'COMMON.COLLECTIONS.MULTI_SELECTION.SELECT_ALL_ON_PAGE': 'Select all on page',
  'COMMON.COLLECTIONS.MULTI_SELECTION.SELECTION_SIZE': '{{itemsNumber}} selected',
  'COMMON.COLLECTIONS.PAGING.PAGE_SIZE_LABEL': 'results per page',
  'COMMON.COLLECTIONS.PAGING.RANGE_INFO': '{{pageRange}} of {{totalLength}}',
  'COMMON.COLLECTIONS.PAGING.RESULTS_INFO': '{{rangeInfo}} results',
  'COMMON.COLLECTIONS.UNSAVED_CHANGES.ACTIONS.DISCARD_CHANGES': 'Discard changes',
  'COMMON.COLLECTIONS.UNSAVED_CHANGES.ACTIONS.RETURN_TO_PAGE': 'Return to page',
  'COMMON.COLLECTIONS.UNSAVED_CHANGES.DESCRIPTION': 'You are about to leave page without saving changes. Are you sure you want to do this?',
  'COMMON.COLLECTIONS.UNSAVED_CHANGES.TITLE': 'Unsaved changes',
  'COMMON.LABELS.ANONYMOUS': 'Anonymous',
  'COMMON.LABELS.AND': 'And',
  'COMMON.LABELS.CATEGORY': 'Category',
  'COMMON.LABELS.DATE': 'Date',
  'COMMON.LABELS.DEPARTMENT': 'Department',
  'COMMON.LABELS.DESCRIPTION': 'Description',
  'COMMON.LABELS.DETAILS': 'Details',
  'COMMON.LABELS.DISABLED': 'Disabled',
  'COMMON.LABELS.DISMISS': 'Dismiss',
  'COMMON.LABELS.EMPTY': 'Empty',
  'COMMON.LABELS.ENABLED': 'Enabled',
  'COMMON.LABELS.FALSE': 'False',
  'COMMON.LABELS.NAME': 'Name',
  'COMMON.LABELS.NOTES': 'Notes',
  'COMMON.LABELS.NO': 'No',
  'COMMON.LABELS.STATUS': 'Status',
  'COMMON.LABELS.TRUE': 'True',
  'COMMON.LABELS.TYPE': 'Type',
  'COMMON.LABELS.YES': 'Yes',
  'COMMON.NOT_APPLICABLE': 'Not applicable',
  'COMMON.PAGE_NOT_ALLOWED.DESCRIPTION_1': 'Sorry, you currently do not have access to view this content.',
  'COMMON.PAGE_NOT_ALLOWED.DESCRIPTION_2': 'Please check your permissions or contact your system administrator for assistance.',
  'COMMON.PAGE_NOT_ALLOWED.HEADER': 'Access restricted',
  'COMMON.PAGE_NOT_FOUND.DESCRIPTION': "Oops! The page you're looking for seems to have disappeared.",
  'COMMON.PAGE_NOT_FOUND.HEADER': 'Page not found',
  'COMMON.PLACEHOLDERS.ALL': 'All',
  'COMMON.PLACEHOLDERS.LOADING': 'Loading...',
  'COMMON.PLACEHOLDERS.SEARCH': 'Search...',
  'COMMON.PLACEHOLDERS.SELECT': 'Select',
  'COMMON.PRODUCTS.HR': 'Human resources',
  'COMMON.SELECT.DEFAULT_VALUE': 'Unassigned',
  'COMMON.SENSITIVE_DATA.TITLE': 'Sensitive data',
  'COMMON.SENSITIVE_DATA.ARIA_TOOGLE': 'Button with an eye icon where data is available to read and eye-crossed when the data is hidden',
  'COMMON.SENSITIVE_DATA.DESCRIPTION':
    'We hide your sensitive data to protect your privacy. For watching the data please turn off the Sensitive toggle button in menu.',
  'COMMON.SNACK_BAR.HTTP_ERROR.MESSAGE': 'Something went wrong.',

  'NAVIGATION.ADMIN': 'Ciphr Sign In Admin',
  'NAVIGATION.COMPANY_LOGO': 'Company logo',
  'NAVIGATION.DESKTOP': 'Desktop version',
  'NAVIGATION.LOG_OUT': 'Log out',
  'NAVIGATION.PROFILE': 'Personal Profile',
  'NAVIGATION.SEARCH': 'Search',

  'VIEWER.ACTIONS.GENERATE': 'Generate',
  'VIEWER.MODULE_PAGE_TITLE': 'Report Viewer',
  'VIEWER.HELPER.TITLE': "Can't see report template?",
  'VIEWER.HELPER.DESCRIPTION':
    'If you can’t see any data in the report view, you may need to provide additional parameters in the filtering options',

  'REPORTING.REPORT_PARAMETERS': 'Report Parameters',
  'REPORTING.REPORT_TYPE': 'Report type',
  'REPORTING.REPORT_TYPE_HELPER': 'Here you can select the type of report you want to generate',
  'REPORTING.TEMPLATES': 'Template applicable date type',
  'REPORTING.TEMPLATES_HELPER':
    'Report templates may change over time. By selecting one of the dates, you choose which template will be used to generate the report.',

  'DEPARTMENTS.TABLE.SEARCH_PLACEHOLDER': 'Search by department name',
  'DEPARTMENTS.COLUMNS.SELECT': 'Select department',
  'DEPARTMENTS.COLUMNS.DEPARTMENT_NAME': 'Department name',

  'DESIGNER.ACTIONS.OPEN': 'Open',
  'DESIGNER.ACTIONS.SAVE': 'Save',
  'DESIGNER.ACTIONS.CANCEL': 'Go back to editing',
  'DESIGNER.ACTIONS.NO': "Don't save changes",
  'DESIGNER.ACTIONS.YES': 'Proceed with saving changes',

  'DESIGNER.MODULE_PAGE_TITLE': 'Report Designer',
  'DESIGNER.OPEN_REPORT_MODAL.TITLE': 'Open Report',

  'DESIGNER.SAVE_REPORT_MODAL.TITLE': 'Save Report',
  'DESIGNER.SAVE_REPORT_MODAL.HELPER.TITLE': 'Financial document',
  'DESIGNER.SAVE_REPORT_MODAL.DESCRIPTION': 'Are you sure to save {{ reportName }}?',
  'DESIGNER.SAVE_REPORT_MODAL.VERSION_DATE_DESCRIPTION':
    'You are modifying a report template. You need to provide a date from which this template will be applicable.',

  'DESIGNER.SAVE_REPORT_MODAL.LABELS.DATE_VERSION': 'Applicable date',
  'DESIGNER.SAVE_REPORT_MODAL.SAVE_SUCCESSFULLY': 'Report has been saved successfully',

  'DESIGNER.CONFIRMATION_MODAL.TITLE': 'Changes detected',
  'DESIGNER.CONFIRMATION_MODAL.DESCRIPTION': 'Report has been changed. Do you want to save changes ?',

  'VALIDATION.MIN_DATE': 'Provide a further date',
  'VALIDATION.REQUIRED': 'Field is required',
};
