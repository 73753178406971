import { inject, Injectable, signal } from '@angular/core';
import { USER_SESSION_API_CLIENT } from './user-session.provider';
import { DOCUMENT } from '@angular/common';
import { SsoSession } from './sso-session.inteface';
import { AUTH_PANEL_SERVICE } from '@ciphr/core/app-navigation/features';
import { SsoSessionEvent } from './sso-session-event.type';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { filter, map, Observable, share, switchMap, takeUntil, takeWhile, tap, timer } from 'rxjs';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { UserSessionComponent } from './user-session.component';

@Injectable({ providedIn: 'root' })
export class UserSessionService {
  private httpClient = inject(USER_SESSION_API_CLIENT);
  private document = inject(DOCUMENT);
  private overlay = inject(Overlay);

  private readonly authPanelService = inject(AUTH_PANEL_SERVICE);

  private _sessionEvent = signal<SsoSessionEvent | null>(null);
  sessionEvent = this._sessionEvent.asReadonly();

  private overlayRef!: OverlayRef;

  private readonly sessionEvent$ = toObservable(this.sessionEvent).pipe(
    filter(Boolean),
    tap((event) => event.type === 'session_expired' && this.logout()),
    share(),
  );
  private readonly expirationTimeReset$ = this.sessionEvent$.pipe(filter((event) => event && event.type !== 'session_expiring'));
  private readonly ssoSessionExpirationTime$ = this.sessionEvent$.pipe(
    tap(() => this.overlayRef && this.overlayRef.detach()),
    map((event) => (event.type === 'session_expiring' ? event.payload.expiresInMinutes : null)),
    tap((minutes) => minutes && this.displayOverlay()),
    share(),
  );
  private readonly remainingTime$ = this.ssoSessionExpirationTime$.pipe(
    filter(Boolean),
    map((minutes) => Math.floor(minutes) * 60),
    switchMap((seconds) =>
      timer(0, 1000).pipe(
        map((value) => (seconds - value) * 1000),
        takeUntil(this.expirationTimeReset$),
        takeWhile((value) => value >= 0),
      ),
    ),
  );

  ssoSessionExpirationTime = toSignal(this.ssoSessionExpirationTime$);
  remainingTime = toSignal(this.remainingTime$);
  private registerSsoSessionScript(scriptContent: string): void {
    const { defaultView } = this.document;
    const ssoSession = defaultView ? ((defaultView as Record<string, any>)['ssosession'] as SsoSession) : null;
    if (!ssoSession) {
      this.addScriptToDOM(scriptContent);
      this.registerSessionListeners();
    }
  }
  private addScriptToDOM(scriptContent: string): void {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = scriptContent;
    document.body.appendChild(script);
  }
  private registerSessionListeners(): void {
    window.addEventListener('session_expiring', (event: Event) =>
      this._sessionEvent.set({ type: 'session_expiring', payload: (event as any).detail }),
    );
    window.addEventListener('session_refreshed', () => this._sessionEvent.set({ type: 'session_refreshed' }));
    window.addEventListener('session_expired', () => this._sessionEvent.set({ type: 'session_expired' }));
  }

  fetchUserSessionScript(): Observable<string> {
    return this.httpClient
      .get('/user-session/script', { responseType: 'text' })
      .pipe(tap((scriptContent) => this.registerSsoSessionScript(scriptContent)));
  }

  refreshSession(): void {
    const { defaultView } = this.document;
    const ssoSession = defaultView ? ((defaultView as Record<string, any>)['ssosession'] as SsoSession) : null;

    if (!ssoSession) {
      throw 'Session has not been initialized';
    }

    ssoSession.keepalive();
  }

  logout(): void {
    this.authPanelService.logOut();
  }

  displayOverlay() {
    this.overlayRef = this.overlay.create({
      hasBackdrop: false,
      width: '100%',
      maxWidth: '540px',
      positionStrategy: this.overlay.position().global().bottom('28px').centerHorizontally(),
    });
    this.overlayRef.attach(new ComponentPortal(UserSessionComponent));
  }
}
