{
  "COMMON.ACTIONS.ADD": "Add",
  "COMMON.ACTIONS.ACCEPT": "Accept",
  "COMMON.ACTIONS.AGREE": "Agree",
  "COMMON.ACTIONS.APPLY": "Apply",
  "COMMON.ACTIONS.BACK": "Back",
  "COMMON.ACTIONS.CANCEL": "Cancel",
  "COMMON.ACTIONS.CONFIRM": "Confirm",
  "COMMON.ACTIONS.CREATE": "Create",
  "COMMON.ACTIONS.CLEAR_ALL": "Clear all",
  "COMMON.ACTIONS.CLOSE": "Close",
  "COMMON.ACTIONS.DECLINE": "Decline",
  "COMMON.ACTIONS.DELETE": "Delete",
  "COMMON.ACTIONS.DOWNLOAD": "Download",
  "COMMON.ACTIONS.DUPLICATE": "Duplicate",
  "COMMON.ACTIONS.EDIT": "Edit",
  "COMMON.ACTIONS.I_UNDERSTAND": "I understand",
  "COMMON.ACTIONS.NEXT": "Next",
  "COMMON.ACTIONS.REMOVE": "Remove",
  "COMMON.ACTIONS.SAVE": "Save",
  "COMMON.ACTIONS.SORT": "Sort",
  "COMMON.ACTIONS.SUBMIT": "Submit",
  "COMMON.ACTIONS.USE": "Use",
  "COMMON.ACTIONS.VIEW_DETAILS": "View details",
  "COMMON.ACTIONS.VERIFY": "Verify",
  "COMMON.ARIA.ACTIONS.BACK_BUTTON": "Back button",
  "COMMON.ARIA.ACTIONS.BULK_BUTTON": "Bulk actions",
  "COMMON.ARIA.ACTIONS.TOGGLE_ARROW": "Toggle expansion panel",
  "COMMON.ARIA.INPUTS.AUTOMATIC_SEARCH": "Search over menu. During typing the search results will be narrowed down to the entered phrase",
  "COMMON.COLLECTIONS.ARIA.BUTTONS.CLOSE": "Close filters drawer",
  "COMMON.COLLECTIONS.ARIA.BUTTONS.FILTER_HEADER": "Toggle button for {{filterKey}} filter",
  "COMMON.COLLECTIONS.ARIA.BUTTON.OVERLAY_MENU": "Button with three dots to toggle overlay menu",
  "COMMON.COLLECTIONS.ARIA.BUTTONS.TOGGLE_DRAWER": "Toggle filters drawer",
  "COMMON.COLLECTIONS.ARIA.SELECT.PAGE_SIZE": "Page size",
  "COMMON.COLLECTIONS.EMPTY.NO_RECORDS.DESCRIPTION": "It appears that there are no records in this table",
  "COMMON.COLLECTIONS.EMPTY.NO_RECORDS.HEADER": "No records here",
  "COMMON.COLLECTIONS.EMPTY.NO_RESULTS.DESCRIPTION": "It seems that we didn’t find what you were looking for",
  "COMMON.COLLECTIONS.EMPTY.NO_RESULTS.HEADER": "No result found",
  "COMMON.COLLECTIONS.FILTERING.APPLIED_FILTERS_NUMBER_BADGE": "{{filtersNumber}} filters applied",
  "COMMON.COLLECTIONS.FILTERING.DATE_RANGE.AFTER": "{{filterLabel}} after",
  "COMMON.COLLECTIONS.FILTERING.DATE_RANGE.BEFORE": "{{filterLabel}} before",
  "COMMON.COLLECTIONS.FILTERING.DATE_RANGE.NOT_PROVIDED": "{{filterLabel}} not provided",
  "COMMON.COLLECTIONS.FILTERING.DRAWER.ACTIONS.CLEAR_ALL": "Clear all",
  "COMMON.COLLECTIONS.FILTERING.DRAWER.ACTIONS.FILTER": "Filter",
  "COMMON.COLLECTIONS.FILTERING.DRAWER.HEADER": "Filters",
  "COMMON.COLLECTIONS.HEADERS.ACTIONS": "Actions",
  "COMMON.COLLECTIONS.HEADERS.SELECT": "Select",
  "COMMON.COLLECTIONS.MULTI_SELECTION.SELECT_ALL_ON_PAGE": "Select all on page",
  "COMMON.COLLECTIONS.MULTI_SELECTION.SELECTION_SIZE": "{{itemsNumber}} selected",
  "COMMON.COLLECTIONS.PAGING.PAGE_SIZE_LABEL": "results per page",
  "COMMON.COLLECTIONS.PAGING.RANGE_INFO": "{{pageRange}} of {{totalLength}}",
  "COMMON.COLLECTIONS.PAGING.RESULTS_INFO": "{{rangeInfo}} results",
  "COMMON.COLLECTIONS.UNSAVED_CHANGES.ACTIONS.DISCARD_CHANGES": "Discard changes",
  "COMMON.COLLECTIONS.UNSAVED_CHANGES.ACTIONS.RETURN_TO_PAGE": "Stay and edit",
  "COMMON.COLLECTIONS.UNSAVED_CHANGES.DESCRIPTION": "Unsaved changes will be lost if you leave now",
  "COMMON.COLLECTIONS.UNSAVED_CHANGES.TITLE": "Discard changes?",
  "COMMON.LABELS.ANONYMOUS": "Anonymous",
  "COMMON.LABELS.AND": "And",
  "COMMON.LABELS.CATEGORY": "Category",
  "COMMON.LABELS.DATE": "Date",
  "COMMON.LABELS.DEPARTMENT": "Department",
  "COMMON.LABELS.DESCRIPTION": "Description",
  "COMMON.LABELS.DETAILS": "Details",
  "COMMON.LABELS.DISABLED": "Disabled",
  "COMMON.LABELS.DISMISS": "Dismiss",
  "COMMON.LABELS.EMPTY": "Empty",
  "COMMON.LABELS.ENABLED": "Enabled",
  "COMMON.LABELS.FALSE": "False",
  "COMMON.LABELS.NAME": "Name",
  "COMMON.LABELS.NOTES": "Notes",
  "COMMON.LABELS.NO": "No",
  "COMMON.LABELS.STATUS": "Status",
  "COMMON.LABELS.TRUE": "True",
  "COMMON.LABELS.TYPE": "Type",
  "COMMON.LABELS.YES": "Yes",
  "COMMON.NOT_APPLICABLE": "Not applicable",
  "COMMON.PAGE_NOT_ALLOWED.DESCRIPTION_1": "Sorry, you currently do not have access to view this content.",
  "COMMON.PAGE_NOT_ALLOWED.DESCRIPTION_2": "Please check your permissions or contact your system administrator for assistance.",
  "COMMON.PAGE_NOT_ALLOWED.HEADER": "Access restricted",
  "COMMON.PAGE_NOT_FOUND.DESCRIPTION": "Oops! The page you're looking for seems to have disappeared.",
  "COMMON.PAGE_NOT_FOUND.HEADER": "Page not found",
  "COMMON.PLACEHOLDERS.ALL": "All",
  "COMMON.PLACEHOLDERS.LOADING": "Loading...",
  "COMMON.PLACEHOLDERS.SEARCH": "Search...",
  "COMMON.PLACEHOLDERS.SELECT": "Select",
  "COMMON.PRODUCTS.HR": "Human resources",
  "COMMON.SELECT.DEFAULT_VALUE": "Unassigned",
  "COMMON.SENSITIVE_DATA.TITLE": "Sensitive data",
  "COMMON.SENSITIVE_DATA.ARIA_TOOGLE": "Button with an eye icon where data is available to read and eye-crossed when the data is hidden",
  "COMMON.SENSITIVE_DATA.DESCRIPTION": "We hide your sensitive data to protect your privacy. For watching the data please turn off the Sensitive toggle button in menu.",

  "COMMON.USER_SESSION.DIALOG.TITLE": "Session expiration",
  "COMMON.USER_SESSION.DIALOG.DESCRIPTION": "Your session will expire in",
  "COMMON.USER_SESSION.DIALOG.TIME_UNIT": "min",
  "COMMON.USER_SESSION.DIALOG.ACTIONS.REMAIN_LOGGED": "Stay logged in",
  "COMMON.USER_SESSION.DIALOG.ACTIONS.LOG_OUT": "Log out",

  "COMMON.PWA_NOTIFICATION.ADD_TO_HOME_SCREEN": "Add to home screen",
  "COMMON.PWA_NOTIFICATION.ANDROID.DESCRIPTION": "When you click this button, the app will be added to your phones home screen.",
  "COMMON.PWA_NOTIFICATION.IOS.DESCRIPTION": "Add the app to your home screen to have it easily accessible at any time.",
  "COMMON.PWA_NOTIFICATION.IOS.STEP_1": "Click below on",
  "COMMON.PWA_NOTIFICATION.IOS.STEP_2": "Add to home screen",
  "COMMON.PWA_NOTIFICATION.IOS.CHOOSE": "Choose",

  "COMMON.PWA_NOTIFICATION.UPDATE.TITLE": "New version available",
  "COMMON.PWA_NOTIFICATION.UPDATE.DESCRIPTION": "There is a new version of this application available.",

  "COMMON.PWA_NOTIFICATION.ACTIONS.UPDATE": "Update",
  "COMMON.PWA_NOTIFICATION.ACTIONS.UNDERSTAND": "Got it",
  "COMMON.PWA_NOTIFICATION.ACTIONS.ADD": "Add",

  "COMMON.DOCUMENTS.MODALS.DOWNLOAD_CONFIRMATION.ARIA.BUTTONS.CLOSE": "Close document downloading drawer",
  "COMMON.DOCUMENTS.MODALS.DOWNLOAD_CONFIRMATION.DESCRIPTION": "By downloading this document you accept the responsibility of deleting your copy when it is no longer needed.",
  "COMMON.DOCUMENTS.MODALS.DOWNLOAD_CONFIRMATION.TITLE": "Document download",

  "COMMON.FILE_MANAGER.DIALOG.TITLE": "Preparing downloading ({{ counts }}}",
  "COMMON.FILE_MANAGER.LABELS.FILE": "File",
  "COMMON.FILE_MANAGER.LABELS.STATUS": "Status",
  "COMMON.FILE_MANAGER.LABELS.ACTION": "Action",


  "COMMON.FILE_MANAGER.ACTIONS.CANCEL": "Cancel",
  "COMMON.FILE_MANAGER.ACTIONS.CLOSE": "Close",
  "COMMON.FILE_MANAGER.ACTIONS.TRY_AGAIN": "Try again",
  "COMMON.FILE_MANAGER.ACTIONS.PREPARING": "Preparing download ({{ counts }})",

  "COMMON.SNACK_BAR.HTTP_ERROR.MESSAGE": "Something went wrong.",

  "ABSENCES.ABSENCE_MANAGER.ACTIONS.SUBMIT_AND_ADD_NEW": "Submit and add new",
  "ABSENCES.ABSENCE_MANAGER.ACTIONS.UNDO_CHANGES": "Undo changes",
  "ABSENCES.ABSENCE_MANAGER.ACTIONS.UNDO_DELETION_REQUEST": "Undo deletion request",
  "ABSENCES.ABSENCE_MANAGER.ACTIONS.CANCEL": "Cancel request",
  "ABSENCES.ABSENCE_MANAGER.ACTIONS.DELETE": "Delete absence",
  "ABSENCES.ABSENCE_MANAGER.MESSAGES.SAVE.SUCCESS": "Your new absence request has been sent for verification",
  "ABSENCES.ABSENCE_MANAGER.MESSAGES.UPDATE.SUCCESS": "Your request to update an absence has been sent for verification",
  "ABSENCES.ABSENCE_MANAGER.MESSAGES.DELETE_ACCEPTED.SUCCESS": "Your absence has been marked for deletion and sent for verification",
  "ABSENCES.ABSENCE_MANAGER.MESSAGES.CANCEL_PENDING_INSERT.SUCCESS": "Your absence request was cancelled",
  "ABSENCES.ABSENCE_MANAGER.MESSAGES.CANCEL_PENDING_UPDATE.SUCCESS": "You have undone the changes to your absence",
  "ABSENCES.ABSENCE_MANAGER.MESSAGES.CANCEL_PENDING_DELETE.SUCCESS": "Your absence request will not be deleted",
  "ABSENCES.ABSENCE_MANAGER.MESSAGES.EDIT_DISABLED.TITLE": "Verification in progress",
  "ABSENCES.ABSENCE_MANAGER.MESSAGES.EDIT_DISABLED.DESCRIPTION": "You can't make changes to this request as previous changes to it are being verified by your manager",
  "ABSENCES.ABSENCE_MANAGER.MODALS.DESCRIPTION.UNDO_CHANGES": "Undoing your changes is permanent once approved. Do you want to proceed?",
  "ABSENCES.ABSENCE_MANAGER.MODALS.DESCRIPTION.CANCEL": "Are you sure you want to cancel your absence request?",
  "ABSENCES.ABSENCE_MANAGER.MODALS.DESCRIPTION.DELETE": "Are you sure you want to delete your absence?",
  "ABSENCES.ABSENCE_MANAGER.MODALS.DESCRIPTION.DOCUMENT_DELETE": "Are you sure you want to delete this file?",
  "ABSENCES.ABSENCE_MANAGER.MODALS.TITLE.CANCEL": "Request cancellation",
  "ABSENCES.ABSENCE_MANAGER.MODALS.TITLE.DELETE": "Absence deletion",
  "ABSENCES.ABSENCE_MANAGER.MODALS.TITLE.DOCUMENT_DELETE": "Delete file",
  "ABSENCES.ABSENCE_MANAGER.MODALS.BUTTON.CANCEL": "Leave it",
  "ABSENCES.ABSENCE_MANAGER.MODALS.BUTTON.CONFIRM_CANCEL": "Cancel request",
  "ABSENCES.ABSENCE_MANAGER.MODALS.BUTTON.CONFIRM_DELETE": "Delete absence",
  "ABSENCES.ABSENCE_MANAGER.MODALS.DESCRIPTION.MONTHS_DIFFERENCE": "The date range is for {{ monthsDifference }} months. Are you sure?",
  "ABSENCES.ABSENCE_MANAGER.MODALS.DESCRIPTION.OPEN_ENDED": "The date range is open ended. Are you sure?",
  "ABSENCES.ABSENCE_MANAGER.MODALS.DESCRIPTION.OVERLAPPED": "The absence overlaps an existing absence. Do you wish to proceed?",
  "ABSENCES.ABSENCE_MANAGER.MODALS.DESCRIPTION.OVER_LIMIT": "The absence entered will exceed the total holiday entitlement available. Do you wish to proceed and create this absence?",
  "ABSENCES.ABSENCE_MANAGER.MODALS.DESCRIPTION.PUBLIC_HOLIDAYS": "{{ publicHolidays }} day(s) public holiday in period will not be included in the absence",
  "ABSENCES.MODALS.DOCUMENT_UPLOAD.ACTIONS.SELECT_FILE": "Select file",
  "ABSENCES.MODALS.DOCUMENT_UPLOAD.ACTIONS.ATTACH_DOCUMENT": "Attach to request",
  "ABSENCES.MODALS.DOCUMENT_UPLOAD.LABELS.FILE_TO_UPLOAD": "File to upload",
  "ABSENCES.MODALS.DOCUMENT_UPLOAD.LABELS.SELECT_FILE": "Select file or take photo",
  "ABSENCES.MODALS.DOCUMENT_UPLOAD.TITLE": "Add document",
  "ABSENCES.ABSENCE_MANAGER.PAGE_TITLE": "Add absence",
  "ABSENCES.ABSENCE_MANAGER.STEPS.TYPE.TITLE": "Absence type",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.TITLE": "Date",
  "ABSENCES.ABSENCE_MANAGER.STEPS.ADDITIONAL_INFO.TITLE": "Additional info",
  "ABSENCES.ABSENCE_MANAGER.STEPS.TYPE.LABELS.ABSENCE_TYPE": "Absence reason",
  "ABSENCES.ABSENCE_MANAGER.STEPS.TYPE.LABELS.SELECT_LABEL": "Select absence type",
  "ABSENCES.ABSENCE_MANAGER.STEPS.TYPE.LABELS.SICKNESS_REASON": "Sickness reason",
  "ABSENCES.ABSENCE_MANAGER.STEPS.TYPE.PLACEHOLDERS.SELECT": "{{COMMON.PLACEHOLDERS.SELECT}}",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.ACTION.COPY_DOWN": "Copy down",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.CUSTOMISE_TIME": "Customise times if needed",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.DATES_RANGE": "Absence dates range",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.MINUTES_LATE": "Minutes late",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.SELECT_DETAILS": "Select details",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.START_DATE": "Start date",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.START_TIME": "Start time",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.END_DATE": "End date",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.END_TIME": "End time",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.SWITCHER": "Select default template",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.FIRST_HALF": "Shift start",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.FULL_DAY": "Full day",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.SECOND_HALF": "Shift end",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.BREAK": "Break",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.DAYS": "Days",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.HOURS": "Hours",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.CONTINUOUS": "Continuous absence",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.LABELS.WORK_DAY": "Work day",
  "ABSENCES.ABSENCE_MANAGER.STEPS.DATE.MESSAGES.NON_WORKING_DAY": "The selected date range falls on a non-working day",
  "ABSENCES.ABSENCE_MANAGER.STEPS.ADDITIONAL_INFO.ACTIONS.ADD_DOCUMENT": "Add document",
  "ABSENCES.ABSENCE_MANAGER.STEPS.ADDITIONAL_INFO.LABELS.ADDITIONAL_INFORMATION": "Additional information",
  "ABSENCES.ABSENCE_MANAGER.STEPS.ADDITIONAL_INFO.LABELS.DOCTOR_CERTIFICATE": "Doctor's certificate",
  "ABSENCES.ABSENCE_MANAGER.STEPS.ADDITIONAL_INFO.LABELS.DOCUMENTS": "Documents",
  "ABSENCES.ABSENCE_MANAGER.STEPS.ADDITIONAL_INFO.LABELS.NOTES": "Notes",
  "ABSENCES.ABSENCE_MANAGER.STEPS.ADDITIONAL_INFO.LABELS.VALID_TO": "Valid to",
  "ABSENCES.ABSENCE_MANAGER.SUMMARY.LABELS.ENTITLEMENT_FOR": "Absence",
  "ABSENCES.ABSENCE_MANAGER.SUMMARY.LABELS.TOTAL_ENTITLEMENT": "Total entitlement",
  "ABSENCES.ABSENCE_MANAGER.SUMMARY.LABELS.ABSENCE_TYPE": "Absence reason",
  "ABSENCES.ABSENCE_MANAGER.SUMMARY.LABELS.SICKNESS_REASON": "Sickness reason",
  "ABSENCES.ABSENCE_MANAGER.SUMMARY.LABELS.DURATION": "Duration",
  "ABSENCES.ABSENCE_MANAGER.SUMMARY.LABELS.MINUTES_LATE": "Minutes late",

  "ABSENCES.ACTIONS.OK": "Ok",
  "ABSENCES.ACTIONS.DETAILS": "See details",
  "ABSENCES.ACTIONS.BOOK_ABSENCE": "Book absence",
  "ABSENCES.ARIA.BUTTONS.CLOSE_DOCUMENT_UPLOAD_MODEL": "Close absence document upload modal",
  "ABSENCES.ARIA.NAVIGATE.PREVIOUS_DAYS": "Previous days",
  "ABSENCES.ARIA.NAVIGATE.NEXT_DAYS": "Next days",
  "ABSENCES.ARIA.DRAWER.CLOSE": "Close",
  "ABSENCES.MODULE.WHOS_OFF.PAGE_TITLE": "Who's off",
  "ABSENCES.MODULE.WHOS_OFF.GLOBAL": "Global",
  "ABSENCES.MODULE.WHOS_OFF.TEAM": "Team",
  "ABSENCES.MODULE.WHOS_OFF.ABSENCES_KEY": "Key",
  "ABSENCES.MODULE.WHOS_OFF.LEGEND": "Legend",
  "ABSENCES.MODULE_PAGE_TITLE": "Absences",
  "ABSENCES.LIST.ACTIONS.ADD_ABSENCE": "Add absence",
  "ABSENCES.LIST.ENTITLEMENT": "Entitlement",
  "ABSENCES.LIST.PENDING": "Pending",
  "ABSENCES.LIST.REMAINING": "Remaining",
  "ABSENCES.LIST.ARIA.SELECT_ABSENCE": "Select absence {{ displayName }}",
  "ABSENCES.UNIT.DAYS": "Day(s)",
  "ABSENCES.UNIT.HOURS": "Hour(s)",

  "ABSENCES.LABELS.FROM": "from",
  "ABSENCES.LABELS.TO": "to",

  "ABSENCES.WIDGETS_TITLE": "Absence",
  "ABSENCES.UPCOMING": "Upcoming absence",
  "ABSENCES.REQUEST": "Requests",
  "ABSENCES.HOLIDAY_LEFT": "Holiday left",
  "ABSENCES.EMPTY_ABSENCE_HOLIDAY": "You have no upcoming holidays",
  "ABSENCES.EMPTY_ABSENCE_REQUEST": "You have no holidays request",

  "ABSENCES.STATUS.ALL": "All",
  "ABSENCES.STATUS.VERIFIED": "Verified",
  "ABSENCES.STATUS.PENDING": "Pending",
  "ABSENCES.SUB_STATUS.INSERT": "Insert",
  "ABSENCES.SUB_STATUS.UPDATE": "Update",
  "ABSENCES.SUB_STATUS.DELETE": "Deletion",

  "DASHBOARD.MODULE_PAGE_TITLE": "Dashboard",

  "DOCUMENTS.ACTIONS.SELECT_DOCUMENT": "New document",
  "DOCUMENTS.ARIA.BUTTONS.CLOSE_DOCUMENT_UPLOAD_MODEL": "Close personal document upload modal",
  "DOCUMENTS.ARIA.BUTTONS.DOWNLOAD_DOCUMENT": "Download {{documentName}} file",
  "DOCUMENTS.ARIA.BUTTONS.OPEN_FILTERS_DRAWER": "Open filters drawer",
  "DOCUMENTS.ARIA.BUTTONS.OPEN_SORTING_DRAWER": "Open sorting drawer",
  "DOCUMENTS.HEADERS.PERSONAL_DOCUMENTS": "Personal documents",
  "DOCUMENTS.MESSAGES.UPLOAD.SUCCESS": "Document successfully uploaded",
  "DOCUMENTS.MODALS.PERSONAL_DOCUMENT_UPLOAD.ACTIONS.UPLOAD_DOCUMENT": "Add document",
  "DOCUMENTS.MODALS.PERSONAL_DOCUMENT_UPLOAD.LABELS.FILE_TO_UPLOAD": "File to upload",
  "DOCUMENTS.MODALS.PERSONAL_DOCUMENT_UPLOAD.LABELS.SELECT_FILE": "Select file or take photo",
  "DOCUMENTS.MODALS.PERSONAL_DOCUMENT_UPLOAD.PLACEHOLDERS.CATEGORY": "Select document category",
  "DOCUMENTS.MODALS.PERSONAL_DOCUMENT_UPLOAD.TITLE": "Upload document",
  "DOCUMENTS.MODULE_PAGE_TITLE": "Documents",
  "DOCUMENTS.NAV_ITEMS.P11D": "P11D",
  "DOCUMENTS.NAV_ITEMS.P60": "P60",
  "DOCUMENTS.NAV_ITEMS.PAYSLIP": "Payslip",
  "DOCUMENTS.NAV_ITEMS.PERSONAL": "Personal",
  "DOCUMENTS.PERSONAL_DOCUMENTS.HEADERS.CATEGORY": "Category",
  "DOCUMENTS.PERSONAL_DOCUMENTS.HEADERS.DATE": "Date",
  "DOCUMENTS.PERSONAL_DOCUMENTS.HEADERS.DOCUMENT_NAME": "Document name",

  "EMPLOYEES.ACTIONS.GIVE_REWARD_POINTS": "Give reward points",
  "EMPLOYEES.ARIA.BUTTONS.CLOSE_DETAILS_PAGE": "Close employee details page",
  "EMPLOYEES.ARIA.BUTTONS.CLOSE_REWARD_POINTS_LIST": "Close employee reward points list",
  "EMPLOYEES.ARIA.BUTTONS.DELETE_REWARD_POINTS": "Delete reward points",
  "EMPLOYEES.ARIA.BUTTONS.GIVE_REWARD_POINTS": "Give reward points",
  "EMPLOYEES.HEADERS.CONTACT_DETAILS": "Contact details",
  "EMPLOYEES.LABELS.JOB_TITLE": "Job title",
  "EMPLOYEES.LABELS.MANAGER": "Manager",
  "EMPLOYEES.MODALS.REWARD_POINTS.TITLE": "Reward points",
  "EMPLOYEES.MODALS.REWARD_POINTS_FORM.ARIA.BUTTONS.CLOSE": "Close reward points form drawer",
  "EMPLOYEES.MODULE_PAGE_TITLE": "Employees",
  "EMPLOYEES.REWARD_POINTS_AMOUNT": "{{rewardPointsAmount}} reward points",
  "EMPLOYEES.REWARD_POINTS_FORM.HELPER_TEXT": "Give reward points to {{rewardPointsReceiver}}",
  "EMPLOYEES.REWARD_POINTS_FORM.LABELS.DETAIL": "Detail",
  "EMPLOYEES.REWARD_POINTS_FORM.LABELS.REASON": "Reason",
  "EMPLOYEES.REWARD_POINTS_FORM.MESSAGES.AWARD_POINT.SUCCESS": "Point awarded successfully",
  "EMPLOYEES.REWARD_POINTS.MESSAGE.DELETE.SUCCESS": "Reward point deleted successfully",

  "NAVIGATION.ADMIN": "Ciphr Sign In Admin",
  "NAVIGATION.ARIA.BUTTONS.HAMBURGER": "Open navigation",
  "NAVIGATION.COMPANY_LOGO": "Company logo",
  "NAVIGATION.DESKTOP": "Desktop version",
  "NAVIGATION.LOG_OUT": "Log out",
  "NAVIGATION.PROFILE": "Personal Profile",
  "NAVIGATION.SEARCH": "Search",

  "PAYSLIPS.ACTIONS.DOWNLOAD": "Download",

  "PAYSLIPS.MODULE_PAGE_TITLE": "Payslips",
  "PAYSLIPS.FILTERS.DATE": "Date range",
  "PAYSLIPS.FILTERS.OLDEST": "Oldest",
  "PAYSLIPS.FILTERS.NEWEST": "Newest",
  "PAYSLIPS.CURRENT_NET_PAY": "Current net pay",
  "PAYSLIPS.CURRENT_GROSS_PAY": "Current gross pay",
  "PAYSLIPS.TAX_CODE": "TAX Code",
  "PAYSLIPS.NI_NO": "NI no",
  "PAYSLIPS.DATE": "Date",
  "PAYSLIPS.TAX_PERIOD": "TAX period",

  "PAYSLIPS.OVERVIEW_TITLE": "Payslip for",

  "PAYSLIPS.LABELS.PAYMENTS_DEDUCTIONS": "Payments & deductions",
  "PAYSLIPS.LABELS.UNITS": "Units due",
  "PAYSLIPS.LABELS.RATE": "Rate",
  "PAYSLIPS.LABELS.AMOUNT": "Amount",
  "PAYSLIPS.LABELS.GROSS_PAY": "Gross pay",
  "PAYSLIPS.LABELS.NET_PAY": "Net pay",

  "PAYROLL_APPROVAL.MODULE_PAGE_TITLE": "Payroll Approvals",
  "PAYROLL_APPROVAL.APPROVED": "Approved",
  "PAYROLL_APPROVAL.TO_APPROVE": "To approve",
  "PAYROLL_APPROVAL.TABLE.HEADERS.PAYROLL_NAME": "Payroll Name",
  "PAYROLL_APPROVAL.TABLE.HEADERS.CALCULATION_PERIOD": "Calculation Period",
  "PAYROLL_APPROVAL.TABLE.HEADERS.APPROVED_UP_TO": "Approved Up To",
  "PAYROLL_APPROVAL.TABLE.HEADERS.AMOUNT": "Amount",
  "PAYROLL_APPROVAL.TABLE.HEADERS.APPROVED_BY": "Approved by",
  "PAYROLL_APPROVAL.ACTIONS.APPROVE": "Approve",
  "PAYROLL_APPROVAL.APPROVE.SUCCESSFULLY": "{{ displayName }} payroll has been approved successfully",

  "POLICY_ACCEPTANCE.FILTER.ALL": "All",
  "POLICY_ACCEPTANCE.FILTER.PENDING": "Pending",
  "POLICY_ACCEPTANCE.STATUS.ACCEPTED": "Accepted",
  "POLICY_ACCEPTANCE.STATUS.PENDING": "Pending acceptance",
  "POLICY_ACCEPTANCE.ARIA.SELECT_POLICY": "Select {{ policy }} ",

  "POLICY_ACCEPTANCE.MODULE_PAGE_TITLE": "Policy acceptance",
  "POLICY_ACCEPTANCE.ACCEPT_ALERT": "To accept, you must first read the policy.",
  "POLICY_ACCEPTANCE.HISTORY": "Version history",
  "POLICY_ACCEPTANCE.VERSION": "Version {{ version }} ",

  "POLICY_ACCEPTANCE.DETAILS.LABELS.DESCRIPTION": "Description",
  "POLICY_ACCEPTANCE.DETAILS.LABELS.STATUS": "Status",
  "POLICY_ACCEPTANCE.DETAILS.LABELS.VERSION": "Version",
  "POLICY_ACCEPTANCE.DETAILS.LABELS.DOCUMENT": "Document file name",
  "POLICY_ACCEPTANCE.DETAILS.LABELS.LAST_MODIFIED": "Last modified",
  "POLICY_ACCEPTANCE.DETAILS.LABELS.ACCEPTED_DATE": "Accepted date",
  "POLICY_ACCEPTANCE.DETAILS.LABELS.LINK": "Link",
  "POLICY_ACCEPTANCE.DETAILS.LABELS.DOCUMENT_FILE": "Document",

  "POLICY_ACCEPTANCE.ACTIONS.ACCEPT": "Accept policy",
  "POLICY_ACCEPTANCE.ACTIONS.DOWNLOAD": "Download policy",
  "POLICY_ACCEPTANCE.ACTIONS.OPEN": "Open policy",

  "POLICY_ACCEPTANCE.ARIA.BUTTONS.CLOSE_DETAILS_PAGE": "Close acceptance policy",
  "POLICY_ACCEPTANCE.MESSAGES.POLICY_ACCEPTED": "The policy has been accepted",

  "PERSONAL_DETAILS.MODULE_PAGE_TITLE": "Personal Details",

  "PERSONAL_DETAILS.ADDRESS": "Address",
  "PERSONAL_DETAILS.POST_CODE": "Postcode",
  "PERSONAL_DETAILS.NI_NO": "NI No",
  "PERSONAL_DETAILS.EMAIL": "Email",
  "PERSONAL_DETAILS.TELEPHONE": "Telephone",
  "PERSONAL_DETAILS.ACCOUNT_OWNER": "Account Owner",
  "PERSONAL_DETAILS.BANK_NAME": "Bank Name",
  "PERSONAL_DETAILS.SORT_CODE": "Sort Code",
  "PERSONAL_DETAILS.ACCOUNT_NUMBER": "Account Number",
  "PERSONAL_DETAILS.BACS_REF": "BACS Ref",

  "REPORTS.MODULE_PAGE_TITLE": "Reports",
  "REPORTS.ARIA.BUTTONS.DOWNLOAD_REPORT": "Download {{reportName}} report",

  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.ACTIONS.ADD": "Add new field",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.ARIA.BUTTONS.ADDITION_CONFIRMATION": "Add {{field}} to the field group",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.ARIA.BUTTONS.EXIT_ADDITION_MODE": "Exit field addition mode",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.ARIA.BUTTONS.REMOVE": "Remove {{field}} from field group",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.LABELS.FIELD_GROUP_NAME": "Field group name",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.LABELS.NO_CONFLICTS": "No conflicts",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.MODALS.CONFLICTED_FIELDS_CONFIRMATION.DESCRIPTION": "You are about to assign fields that are currently assigned to different field group. Moving them may change access to those fields for some users. Are you sure you want to move the following fields to this field group?",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.MODALS.CONFLICTED_FIELDS_CONFIRMATION.TABLE.CAPTION": "From group",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.MODALS.CONFLICTED_FIELDS_CONFIRMATION.TABLE.HEADERS.FROM_GROUP": "From group",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.MODALS.CONFLICTED_FIELDS_CONFIRMATION.TABLE.HEADERS.TO_GROUP": "To group",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.MODALS.CONFLICTED_FIELDS_CONFIRMATION.TITLE": "Potential access change",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.PAGE_TITLE.EDIT": "Edit field group",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.PAGE_TITLE.NEW": "Add new field group",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.PLACEHOLDERS.FIELD_SELECT": "Select field",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.PLACEHOLDERS.TABLE_SELECT": "Select table",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.TABLE.CAPTION": "Fields within a field group",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.TABLE.HEADERS.CONFLICT": "Conflict",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.TABLE.HEADERS.FIELD": "Field",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.TABLE.HEADERS.TABLE": "Table",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.TITLES.DETAILS": "{{COMMON.LABELS.DETAILS}}",
  "ROLE_MANAGEMENT.FIELD_GROUP_MANAGER.TITLES.FIELDS": "Fields",
  "ROLE_MANAGEMENT.FIELD_GROUPS.ACTIONS.NEW": "Add new field group",
  "ROLE_MANAGEMENT.FIELD_GROUPS.LABELS.FIELD_GROUP_NAME": "Field group",
  "ROLE_MANAGEMENT.FIELD_GROUPS.MESSAGES.CREATE.SUCCESS": "The field group was successfully created",
  "ROLE_MANAGEMENT.FIELD_GROUPS.MESSAGES.DELETE.SUCCESS": "Field group was successfully deleted",
  "ROLE_MANAGEMENT.FIELD_GROUPS.MESSAGES.MOVE.SUCCESS": "Fields moved successfully",
  "ROLE_MANAGEMENT.FIELD_GROUPS.MESSAGES.UPDATE.SUCCESS": "The field group was successfully modified",
  "ROLE_MANAGEMENT.FIELD_GROUPS.MODALS.DELETE.DESCRIPTION": "Are you sure you want to delete this field group?",
  "ROLE_MANAGEMENT.FIELD_GROUPS.MODALS.DELETE.TITLE": "Delete field group {{ name }}",
  "ROLE_MANAGEMENT.FIELD_GROUPS.MODALS.NON_DELETABLE.DESCRIPTION": "You can’t delete this group since it is used by other system resources. Please check and unassign usage for this field group.",
  "ROLE_MANAGEMENT.FIELD_GROUPS.MODALS.NON_DELETABLE.TITLE": "You can’t delete field group {{ name }}",
  "ROLE_MANAGEMENT.FIELD_GROUPS.MODALS.TRANSFER.ACTIONS.MIGRATE": "Move fields",
  "ROLE_MANAGEMENT.FIELD_GROUPS.MODALS.TRANSFER.DESCRIPTION": "Deleting a field group is not possible when there are fields assigned to it. In order to delete this field group you will need to move the below listed fields to a different field group,",
  "ROLE_MANAGEMENT.FIELD_GROUPS.MODALS.TRANSFER.SELECT.HEADING": "Please, select the group the following fields should be moved to.",
  "ROLE_MANAGEMENT.FIELD_GROUPS.MODALS.TRANSFER.TITLE": "You can’t delete this field group",
  "ROLE_MANAGEMENT.FIELD_GROUPS.MODALS.TRANSFER.WARNING_MESSAGE": "Moving fields between groups will modify what data users can access.",
  "ROLE_MANAGEMENT.FIELD_GROUPS.TABLE.CAPTION": "Field Group List Details",
  "ROLE_MANAGEMENT.FIELD_GROUPS.TABLE.HEADERS.FIELDS_NUMBER": "Number of fields",
  "ROLE_MANAGEMENT.FIELD_GROUPS.TABLE.HEADERS.NAME": "Security field group",
  "ROLE_MANAGEMENT.FIELD_GROUPS.TABLE.HEADERS.USAGE": "Usage",
  "ROLE_MANAGEMENT.FIELD_GROUPS.TABLE.TITLE": "Field groups",
  "ROLE_MANAGEMENT.MODULE_PAGE_TITLE": "Role Management",
  "ROLE_MANAGEMENT.NAV_ITEMS.FIELD_GROUPS": "Field groups",
  "ROLE_MANAGEMENT.NAV_ITEMS.RECORD_GROUPS": "Record groups",
  "ROLE_MANAGEMENT.NAV_ITEMS.ROLES": "Roles",
  "ROLE_MANAGEMENT.NAV_ITEMS.USERS": "Users",

  "ROLE_MANAGEMENT.RECORD_GROUPS.ACTIONS.NEW": "Add new record group",
  "ROLE_MANAGEMENT.RECORD_GROUPS.ACTIONS.EDIT": "Edit record group name",
  "ROLE_MANAGEMENT.RECORD_GROUPS.LABELS.RECORD_GROUP_NAME": "Record group",
  "ROLE_MANAGEMENT.RECORD_GROUPS.MESSAGES.DELETE.SUCCESS": "Record group was successfully deleted",
  "ROLE_MANAGEMENT.RECORD_GROUPS.MESSAGES.MOVE.SUCCESS": "Records moved successfully",
  "ROLE_MANAGEMENT.RECORD_GROUPS.MODALS.CREATE.TITLE": "Create new record group",
  "ROLE_MANAGEMENT.RECORD_GROUPS.MODALS.NAME.LABEL": "Record group name",
  "ROLE_MANAGEMENT.RECORD_GROUPS.MODALS.EDIT.TITLE": "Edit record group",
  "ROLE_MANAGEMENT.RECORD_GROUPS.MODALS.DELETE.DESCRIPTION": "Are you sure you want to delete this record group?",
  "ROLE_MANAGEMENT.RECORD_GROUPS.MODALS.DELETE.TITLE": "Delete record group {{ name }}",
  "ROLE_MANAGEMENT.RECORD_GROUPS.MODALS.NON_DELETABLE.DESCRIPTION": "This record group can’t be deleted. Please contact customer care for further information.",
  "ROLE_MANAGEMENT.RECORD_GROUPS.MODALS.NON_DELETABLE.TITLE": "You can’t delete this group",
  "ROLE_MANAGEMENT.RECORD_GROUPS.MODALS.TRANSFER.ACTIONS.MIGRATE": "Move records",
  "ROLE_MANAGEMENT.RECORD_GROUPS.MODALS.TRANSFER.DESCRIPTION": "Please select a new record group from the dropdown list to reassign the records.",
  "ROLE_MANAGEMENT.RECORD_GROUPS.MODALS.TRANSFER.TITLE": "Before deleting the record group, you need to move records elsewhere",
  "ROLE_MANAGEMENT.RECORD_GROUPS.TABLE.CAPTION": "Record Groups List Details",
  "ROLE_MANAGEMENT.RECORD_GROUPS.TABLE.HEADERS.NAME": "Record group name",
  "ROLE_MANAGEMENT.RECORD_GROUPS.TABLE.HEADERS.RECORDS_NUMBER": "Assigned users",
  "ROLE_MANAGEMENT.RECORD_GROUPS.TABLE.TITLE": "Record groups",
  "ROLE_MANAGEMENT.RECORD_GROUP_MANAGER.ACTIONS.ADD": "Add new users",
  "ROLE_MANAGEMENT.RECORD_GROUP_MANAGER.ADD_USERS.TITLE": "Add users to Record group",
  "ROLE_MANAGEMENT.RECORD_GROUP_MANAGER.ADD_USERS.MESSAGES.WARNING": "You are about to change record visibility based on security model. Some records might become visible for some roles while some might become hidden.",
  "ROLE_MANAGEMENT.RECORD_GROUP_MANAGER.ARIA.BUTTONS.REMOVE": "Remove {{user}} from record group",
  "ROLE_MANAGEMENT.RECORD_GROUP_MANAGER.MESSAGES.ADD_USER.SUCCESS": "Users were successfully added",
  "ROLE_MANAGEMENT.RECORD_GROUP_MANAGER.MESSAGES.REMOVE_USER.SUCCESS": "Users were successfully removed",
  "ROLE_MANAGEMENT.RECORD_GROUP_MANAGER.MODALS.REMOVE_USER.ARIA.BUTTONS.CLONE": "Populate selected record group",
  "ROLE_MANAGEMENT.RECORD_GROUP_MANAGER.MODALS.REMOVE_USER.ARIA.SELECT.NEW_RECORD_GROUP": "New Record Group",
  "ROLE_MANAGEMENT.RECORD_GROUP_MANAGER.MODALS.REMOVE_USER.TITLE": "Remove users from record group",
  "ROLE_MANAGEMENT.RECORD_GROUP_MANAGER.MODALS.REMOVE_USER.TABLE.HEADERS.CURRENT_RECORD_GROUP": "Current record group",
  "ROLE_MANAGEMENT.RECORD_GROUP_MANAGER.MODALS.REMOVE_USER.TABLE.HEADERS.NEW_RECORD_GROUP": "New record group {{required}}",
  "ROLE_MANAGEMENT.RECORD_GROUP_MANAGER.MODALS.REMOVE_USER.MESSAGES.FORM_VALIDATION.ERROR": "All new record group values need to be provided before proceeding",
  "ROLE_MANAGEMENT.RECORD_GROUP_MANAGER.TABLE.TITLE": "Users in this record group",
  "ROLE_MANAGEMENT.RECORD_GROUP_MANAGER.PAGE_TITLE.EDIT": "Edit {{name}}",
  "ROLE_MANAGEMENT.RECORD_GROUP_MANAGER.PAGE_SUB_TITLE.EDIT": "Record group",

  "ROLE_MANAGEMENT.ROLE_MANAGER.FIELD_SELECTION.CAPTION.FIELDGROUPS": "Field group selection",
  "ROLE_MANAGEMENT.ROLE_MANAGER.FIELD_SELECTION.CAPTION.RECORDGROUPS": "Record group selection",
  "ROLE_MANAGEMENT.ROLE_MANAGER.FIELD_SELECTION.HEADERS.SELECT": "Select",
  "ROLE_MANAGEMENT.ROLE_MANAGER.FIELD_SELECTION.HEADERS.NAME.RECORDGROUPS": "Record group",
  "ROLE_MANAGEMENT.ROLE_MANAGER.FIELD_SELECTION.HEADERS.NAME.FIELDGROUPS": "Field group",
  "ROLE_MANAGEMENT.ROLE_MANAGER.PAGE_TITLE.CREATE": "{{ROLE_MANAGEMENT.ROLES.ACTIONS.CREATE}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.PAGE_TITLE.EDIT": "Edit role",
  "ROLE_MANAGEMENT.ROLE_MANAGER.PERMISSIONS.OPTIONS.WRITE": "Write",
  "ROLE_MANAGEMENT.ROLE_MANAGER.PERMISSIONS.OPTIONS.READ": "Read",
  "ROLE_MANAGEMENT.ROLE_MANAGER.PERMISSIONS.OPTIONS.HIDDEN": "Hidden",
  "ROLE_MANAGEMENT.ROLE_MANAGER.PERMISSIONS.OPTIONS.ACCESS": "Access",
  "ROLE_MANAGEMENT.ROLE_MANAGER.PERMISSIONS.OPTIONS.NO_ACCESS": "No access",
  "ROLE_MANAGEMENT.ROLE_MANAGER.PERMISSIONS.OPTIONS.NOACCESS": "{{ROLE_MANAGEMENT.ROLE_MANAGER.PERMISSIONS.OPTIONS.NO_ACCESS}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.PERMISSIONS.OPTIONS.CREATE": "Create",
  "ROLE_MANAGEMENT.ROLE_MANAGER.PERMISSIONS.OPTIONS.CUSTOM": "Custom",
  "ROLE_MANAGEMENT.ROLE_MANAGER.PERMISSIONS.OPTIONS.DELETE": "Delete",
  "ROLE_MANAGEMENT.ROLE_MANAGER.PERMISSIONS.OPTIONS.UPDATE": "Update",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.ACCESSES.TABLE.CAPTION": "{{ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.ACCESSES.TITLE}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.ACCESSES.TABLE.HEADERS.DESCRIPTION": "Actions",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.ACCESSES.TABLE.HEADERS.NAME": "{{ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.DESCRIPTION}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.ACCESSES.TABLE.HEADERS.SELECT": "Access",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.ACCESSES.TITLE": "Access to other Roles",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.ACTIONS.TABLE.CAPTION": "{{ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.ACTIONS.TITLE}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.ACTIONS.TABLE.HEADERS.DESCRIPTION": "Description",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.ACTIONS.TABLE.HEADERS.NAME": "Actions",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.ACTIONS.TABLE.HEADERS.SELECT": "Access",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.ACTIONS.TITLE": "Role Actions",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.DESCRIPTION.HELPERS.ENABLED": "Role must be enabled to add users",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.DESCRIPTION.HELPERS.DISABLED": "Role can not be disabled. Users must be reassigned first.",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.DESCRIPTION.HELPERS.ROLE_TYPE": "If you change the role type, all access settings in the Permissions and next steps will be reset to the default values",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.DESCRIPTION.LABELS.DESCRIPTION": "{{ROLE_MANAGEMENT.ROLES.LABELS.ROLE_DESCRIPTION}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.DESCRIPTION.LABELS.ENABLED": "Enable role",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.DESCRIPTION.LABELS.NAME": "{{ROLE_MANAGEMENT.ROLES.LABELS.ROLE_NAME}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.DESCRIPTION.LABELS.TYPE": "{{ROLE_MANAGEMENT.ROLES.LABELS.ROLE_TYPE}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.DESCRIPTION.PLACEHOLDERS.SELECT": "{{COMMON.PLACEHOLDERS.SELECT}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.DESCRIPTION.TITLE": "Role description",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TITLE": "Role details",

  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.NAV.LINKS.CODE_TABLES": "Code Tables",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.NAV.LINKS.ADDITIONAL_INFORMATION": "Additional Information",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.NAV.LINKS.EXTERNAL_INTEGRATIONS": "External Integrations",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.NAV.EMPTY.NO_SELECTED.HEADER": "No navigation area selected",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.NAV.EMPTY.NO_SELECTED.DESCRIPTION": "Select one of the navigation links to set the permissions",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.PAGE.ADMIN_ONLY": "Admin only",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.PAGE.ENHANCED_SECURITY": "Only review types with enhanced security toggled on are manageable in Role Management. If you don’t see a Review Type here, please check the Review Types configuration",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.PAGE.NAME": "Name",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.PAGE.OWN": "Own permission",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.PAGE.SUBORDINATE": "Subordinate permission",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.PAGE.RECORD_GROUPS": "Record groups",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.PAGE.WRITE_PERMISSIONS": "Write permissions",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.PAGE.ARIA.COPY": "Copy permission",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.TITLE": "Permissions",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.CODE_TABLES.TITLE": "Code Tables",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.CODE_TABLES.HEADERS.CODE_TABLES": "Code tables",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.CODE_TABLES.HEADERS.PERMISSIONS": "Permissions",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.ADDITIONAL_INFORMATION.TITLE": "Additional Information",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.PERMISSIONS.EXTERNAL_INTEGRATIONS.TITLE": "External Integrations",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.DIALOG.CAPTION": "Field groups fields",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.DIALOG.HEADERS.TABLE": "Table",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.DIALOG.HEADERS.FIELDS": "Fields",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.OWN": "Own field security",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.SUBORDINATE": "Subordinate field security",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.FIELD_SECURITY": "Field Security",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.DEFAULT": "Default",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.DESCRIPTION": "This setting will be applied to all existing and new field groups as default",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.ALL_FIELDS": "All other field groups",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.EXCEPTION": "Exception",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.EXCEPTION_DESCRIPTION": "Add exceptions not to follow the default field security",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.HEADERS.FIELD_GROUP": "Field group",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.HEADERS.PERMISSIONS": "Permissions",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.HEADERS.ACTIONS": "Actions",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.ACTIONS.ADD_EXCEPTION": "Add new field group exception",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.ACTIONS.VIEW_FIELDS": "View fields",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.ARIA.REMOVE_FIELD": "Remove field {{ displayName }}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.DIALOG.EXCEPTION_TITLE": "Add field group exception",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.FIELD_GROUPS.TITLE": "Field groups",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.DEFAULT": "Default",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.DESCRIPTION": "This settings will be applied to all existing and new record groups and field groups as default",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.EXCEPTION": "Record group exceptions",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.EXCEPTION_DESCRIPTION": "Add exceptions not to follow the default record group security",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.ALL_FIELDS": "All Field groups",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.ALL_RECORDS": "All Record groups",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.HEADERS.FIELD_GROUP": "Field group",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.HEADERS.PERMISSIONS": "Permissions",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.HEADERS.ACTIONS": "Actions",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.TITLE": "Record groups",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.ACTIONS.ADD_RECORD_GROUP_EXCEPTION": "Add new record group exception",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.ACTIONS.ADD_FIELD_GROUP_EXCEPTION": "Add new field group exception",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.ACTIONS.VIEW_FIELDS": "View fields",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.DIALOG.EXCEPTION_TITLE": "Add record group exception",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.DIALOG.EXCEPTION_FIELD_GROUP_TITLE": "Add field group exception to record group",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.ARIA.REMOVE_FIELD": "Remove field {{ displayName }}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.ARIA.REMOVE_EXCEPTION": "Remove exception {{ displayName }}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.ARIA.BLOCKED_ALL_RECORDS": "Blocked remove icon for all record fields group",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.ARIA.BLOCKED_ALL_FIELDS": "Blocked remove icon for all fields groups",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.LABELS.NOT_APPLICABLE": "Not applicable",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.TITLE": "Review changes",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.CHANGES_BADGE": "{{count}} changes",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.CHANGED_TO": "changed to",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.LABELS.ROLE_DESCRIPTION.TITLE": "{{ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.DESCRIPTION.TITLE}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.LABELS.ROLE_ACCESS.TITLE": "{{ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.ACCESSES.TITLE}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.LABELS.ROLE_ACTIONS.TITLE": "{{ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.DETAILS.TABS.ACTIONS.TITLE}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.ACCESS.TABLE.CAPTION": "Role access changes",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.DESCRIPTION.TABLE.CAPTION": "Role description changes",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.ACTIONS.TABLE.CAPTION": "Role actions changes",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.TABLE.HEADERS.DESCRIPTION_NAME": "Item",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.TABLE.HEADERS.DESCRIPTION_CHANGE": "Change",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.TABLE.HEADERS.ACCESS_NAME": "Item",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.TABLE.HEADERS.ACCESS_CHANGE": "Change",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.TABLE.HEADERS.ACTIONS_NAME": "Item",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.TABLE.HEADERS.ACTIONS_CHANGE": "Before",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.TABLE.BODY.ROLE_NAME": "{{ROLE_MANAGEMENT.ROLES.LABELS.ROLE_NAME}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.TABLE.BODY.ROLE_TYPE": "{{ROLE_MANAGEMENT.ROLES.LABELS.ROLE_TYPE}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.TABLE.BODY.ROLE_DESCRIPTION": "{{ROLE_MANAGEMENT.ROLES.LABELS.ROLE_DESCRIPTION}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.DETAILS.TABLE.BODY.ROLE_ENABLED": "Role Enabled",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.PERMISSIONS.TABLE.CAPTION": "{{area}} changes",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.PERMISSIONS.TABLE.HEADERS.OWN_PERMISSION": "Own permission",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.PERMISSIONS.TABLE.HEADERS.RECORD_GROUPS": "Record Groups",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.PERMISSIONS.TABLE.HEADERS.SUBORDINATE_PERMISSION": "Subordinate permission",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.FIELD_GROUPS.OWN.TABLE.CAPTION": "Own field security changes",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.FIELD_GROUPS.SUBORDINATE.TABLE.CAPTION": "Subordinate field security changes",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.FIELD_GROUPS.LABELS.OWN": "Own field security",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.FIELD_GROUPS.LABELS.SUBORDINATE": "Subordinate field security",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.FIELD_GROUPS.TABLE.HEADERS.FIELD_GROUP_NAME": "{{ROLE_MANAGEMENT.FIELD_GROUPS.LABELS.FIELD_GROUP_NAME}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.FIELD_GROUPS.TABLE.HEADERS.FIELD_GROUP_CHANGE": "Change",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.FIELD_GROUPS.TABLE.BODY.ALL_OWN_FIELD_GROUPS": "All own field groups",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.FIELD_GROUPS.TABLE.BODY.ALL_SUBORDINATE_FIELD_GROUPS": "All subordinatee field groups",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.RECORD_GROUPS.TABLE.CAPTION": "Record groups changes",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.RECORD_GROUPS.TABLE.HEADERS.NAME": "Name",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.RECORD_GROUPS.TABLE.HEADERS.TYPE": "Type",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.RECORD_GROUPS.TABLE.HEADERS.ACTION": "Action",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.RECORD_GROUPS.TABLE.BODY.ACTION": "Action",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.RECORD_GROUPS.TABLE.BODY.DEFAULT": "Default",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.RECORD_GROUPS.TABLE.BODY.ALL_RECORD_GROUPS": "{{ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.ALL_RECORDS}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.RECORD_GROUPS.TABLE.BODY.ALL_FIELD_GROUPS": "{{ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.RECORD_GROUPS.ALL_FIELDS}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.RECORD_GROUPS.TABLE.BODY.RECORD_GROUP_NAME": "{{ROLE_MANAGEMENT.RECORD_GROUPS.LABELS.RECORD_GROUP_NAME}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.RECORD_GROUPS.TABLE.BODY.FIELD_GROUP_NAME": "{{ROLE_MANAGEMENT.FIELD_GROUPS.LABELS.FIELD_GROUP_NAME}}",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.USERS.TABLE.CAPTION": "Assigned users changes",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.USERS.SEARCH_PLACEHOLDER": "Search",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.USERS.SHOW": "Show",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.USERS.STATUS.ALL": "All",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.USERS.STATUS.ADDED": "Added",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.USERS.STATUS.REMOVED": "Removed",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.MESSAGES.ROLE_CREATED": "Role has been created successfully",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.SUMMARY.MESSAGES.ROLE_UPDATED": "Role has been updated successfully",

  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.USERS.ACTIONS.ADD": "Add new User",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.USERS.ARIA.BUTTONS.REMOVE": "Remove {{user}} from users list",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.USERS.DISABLED.TITLE": "Assign Users",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.USERS.DISABLED.MESSAGE.TITLE": "Enable role first",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.USERS.DISABLED.MESSAGE.TEXT": "You can't add any users because role is disabled",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.USERS.ENABLED.TITLE": "Users list",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.USERS.TITLE": "Users",

  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.USERS.WIZARD.NAV.SUBSET_TOOL": "Subset tool",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.USERS.WIZARD.NAV.USERS": "Users",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.USERS.WIZARD.TITLE": "Add users",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.USERS.WIZARD.STEP.FILTERED_USERS.TITLE": "Filter’s results",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.USERS.WIZARD.STEP.FILTERED_USERS.ACTIONS.ADD_USERS": "Add users",
  "ROLE_MANAGEMENT.ROLE_MANAGER.STEPS.USERS.WIZARD.STEP.FILTERED_USERS.ACTIONS.BACK": "{{COMMON.ACTIONS.BACK}}",

  "ROLE_MANAGEMENT.ROLES.ACTIONS.CREATE": "Create role",
  "ROLE_MANAGEMENT.ROLES.LABELS.ROLE_DESCRIPTION": "Role description",
  "ROLE_MANAGEMENT.ROLES.LABELS.ROLE_NAME": "Role name",
  "ROLE_MANAGEMENT.ROLES.LABELS.ROLE_TYPE": "Role type",
  "ROLE_MANAGEMENT.ROLES.MESSAGES.DELETE.SUCCESS": "Roles were successfully deleted",
  "ROLE_MANAGEMENT.ROLES.MESSAGES.DUPLICATE.SUCCESS": "The role was successfully duplicated",
  "ROLE_MANAGEMENT.ROLES.MODALS.DELETE.CAPTION": "List of roles to delete",
  "ROLE_MANAGEMENT.ROLES.MODALS.DELETE.WARNING_MESSAGE": "If you delete the role, all the users will be unassigned from it.",
  "ROLE_MANAGEMENT.ROLES.TABLE.CAPTION": "Roles List Details",
  "ROLE_MANAGEMENT.ROLES.TABLE.HEADERS.DESCRIPTION": "{{COMMON.LABELS.DESCRIPTION}}",
  "ROLE_MANAGEMENT.ROLES.TABLE.HEADERS.NAME": "{{ROLE_MANAGEMENT.ROLES.LABELS.ROLE_NAME}}",
  "ROLE_MANAGEMENT.ROLES.TABLE.HEADERS.STATUS": "{{COMMON.LABELS.STATUS}}",
  "ROLE_MANAGEMENT.ROLES.TABLE.HEADERS.TYPE": "{{COMMON.LABELS.TYPE}}",
  "ROLE_MANAGEMENT.ROLES.TABLE.HEADERS.USERS_NUMBER": "Number of users",
  "ROLE_MANAGEMENT.ROLES.TABLE.TITLE": "{{product}} roles",
  "ROLE_MANAGEMENT.TABS.HR": "{{COMMON.PRODUCTS.HR}}",
  "ROLE_MANAGEMENT.USERS.DETAILS.TITLES.MAIN_INFORMATION": "Main Information",
  "ROLE_MANAGEMENT.USERS.DETAILS.TITLES.ROLES": "Roles",
  "ROLE_MANAGEMENT.USERS.MESSAGES.BLOCK.SUCCESS": "Users' status were successfully changed",
  "ROLE_MANAGEMENT.USERS.MESSAGES.CHANGE_ROLE.SUCCESS": "Users' roles were successfully changed",
  "ROLE_MANAGEMENT.USERS.MODALS.BLOCK.BLOCKING_EXPLANATION": "Blocking a user or users means blocking access to the HR product but not blocking the Single Sign On access of that user or users.",
  "ROLE_MANAGEMENT.USERS.MODALS.BLOCK.TITLE": "Warning",
  "ROLE_MANAGEMENT.USERS.MODALS.BLOCK.UNBLOCKING_EXPLANATION": "You can unblock a user or users at any time, they’re role configuration is stored and won’t change after unblocking.",
  "ROLE_MANAGEMENT.USERS.MODALS.CHANGE_ROLE.ACTIONS.CHANGE": "Change",
  "ROLE_MANAGEMENT.USERS.MODALS.CHANGE_ROLE.ARIA.BUTTONS.CLONE": "Populate selected role",
  "ROLE_MANAGEMENT.USERS.MODALS.CHANGE_ROLE.ARIA.SELECT.NEW_ROLE": "New User's Role",
  "ROLE_MANAGEMENT.USERS.MODALS.CHANGE_ROLE.MESSAGES.FORM_VALIDATION.ERROR": "All new role values need to be provided before proceeding",
  "ROLE_MANAGEMENT.USERS.MODALS.CHANGE_ROLE.MESSAGES.WARNING": "You are about to change your own role. This will affect your own permissions.",
  "ROLE_MANAGEMENT.USERS.MODALS.CHANGE_ROLE.TITLE": "Change user's role",
  "ROLE_MANAGEMENT.USERS.MODALS.CHANGE_ROLE.SUB_TITLE": "You are about to add user role for the following {{number}} employees",
  "ROLE_MANAGEMENT.USERS.MODALS.CHANGE_ROLE.TABLE.HEADERS.CURRENT_ROLE": "Current role",
  "ROLE_MANAGEMENT.USERS.MODALS.CHANGE_ROLE.TABLE.HEADERS.CURRENT_ROLE_TYPE": "Current role type",
  "ROLE_MANAGEMENT.USERS.MODALS.CHANGE_ROLE.TABLE.HEADERS.NEW_ROLE": "New role {{required}}",
  "ROLE_MANAGEMENT.USERS.MODALS.CHANGE_ROLE.TABLE.HEADERS.NEW_ROLE_TYPE": "New role type",
  "ROLE_MANAGEMENT.USERS.TABLE.ACTIONS.BLOCK": "Block",
  "ROLE_MANAGEMENT.USERS.TABLE.ACTIONS.BLOCK_USERS": "Block users",
  "ROLE_MANAGEMENT.USERS.TABLE.ACTIONS.CHANGE_ROLE": "Change role",
  "ROLE_MANAGEMENT.USERS.TABLE.ACTIONS.UNBLOCK": "Unblock",
  "ROLE_MANAGEMENT.USERS.TABLE.CAPTION": "Users List Details",
  "ROLE_MANAGEMENT.USERS.TABLE.HEADERS.DEPARTMENT": "{{COMMON.LABELS.DEPARTMENT}}",
  "ROLE_MANAGEMENT.USERS.TABLE.HEADERS.EMPLOYEE_NUMBER": "Employee number",
  "ROLE_MANAGEMENT.USERS.TABLE.HEADERS.JOB_TITLE": "Job Title",
  "ROLE_MANAGEMENT.USERS.TABLE.HEADERS.NAME": "User name",
  "ROLE_MANAGEMENT.USERS.TABLE.HEADERS.ROLE_NAME": "Role name",
  "ROLE_MANAGEMENT.USERS.TABLE.HEADERS.ROLE_TYPE": "Role type",
  "ROLE_MANAGEMENT.USERS.TABLE.HEADERS.STATUS": "{{COMMON.LABELS.STATUS}}",
  "ROLE_MANAGEMENT.USERS.TABLE.SEARCH_PLACEHOLDER": "Search by name / employee number",
  "ROLE_MANAGEMENT.USERS.TABLE.TITLE": "{{product}} users",
  "SUBSET_TOOL.ACTIONS.OPEN": "Subset tool",
  "SUBSET_TOOL.ACTIONS.OPEN.SUMMARY": "Summary",
  "SUBSET_TOOL.ACTIONS.OPEN.OVERLAY_MENU": "Subset applied",
  "SUBSET_TOOL.ACTIONS.CREATE": "Create new subset",
  "SUBSET_TOOL.ACTIONS.UNAPPLY": "Unapply subset",
  "SUBSET_TOOL.MODALS.TITLE": "Subset Tool",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DATA_FIELDS.ACTIONS.ACTIVATE_PLACEHOLDER": "Click to select data field",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DATA_FIELDS.ACTIONS.ADD_CONDITION": "Add condition",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DATA_FIELDS.ACTIONS.SELECT_DATA_FIELD": "Select data field",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DATA_FIELDS.ARIA.BUTTONS.REMOVE_FILTER_CONDITION": "Remove filter condition",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DATA_FIELDS.ARIA.BUTTONS.REMOVE_FILTERS_GROUP": "Remove filters group",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DATA_FIELDS.ARIA.TABLE_HEADER": "Toggle button for {{table}} table",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DATA_FIELDS.FAVOURITES": "Favourites",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DATA_FIELDS.LABELS.CONDITION": "Condition",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DATA_FIELDS.LABELS.DATA_FIELD_NAME": "Data field name",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DATA_FIELDS.LABELS.VALUE": "Value",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DATA_FIELDS.PLACEHOLDERS.VALUE_SELECT": "Select value",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DATA_FIELDS.SEPARATORS.AND": "and",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DATA_FIELDS.SEPARATORS.OR": "or",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DATA_FIELDS.TITLE": "Data fields",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DATA_FIELDS.VALIDATION_WARNING": "Will be removed if left empty",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.HELPER.MANAGER_LEVEL": "In example selecting “4” would show all your subordinates up to 4 levels",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.HELPER.RECORD_CONTEXT": "Select these options to show either people with only one record or choose between people with multiple records to show their primary / secondary records (or all of the above)",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.HELPER.STATE": "The choice made here will influence the set of data fields available in the next step",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.HELPER.STATE.WARNING": "The changes made here will delete all the data provided previously in the Data fields step",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.LABELS.MANAGER_LEVEL": "Manager level",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.LABELS.RECORD_CONTEXT": "Record context",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.LABELS.SELECT_DATE": "Select date",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.LABELS.STANDARD_SETS": "Standard sets",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.LABELS.STATE": "State",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.OPTIONS.RECORD_CONTEXT_1": "One record only",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.OPTIONS.RECORD_CONTEXT_2": "Primary record",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.OPTIONS.RECORD_CONTEXT_3": "Secondary record",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.OPTIONS.STATE_1": "Current state",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.OPTIONS.STATE_1_INFO": "(based on Personal Details)",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.OPTIONS.STATE_2": "State in a given point in time",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.OPTIONS.STATE_2_INFO": "(based on Job and pay)",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.PLACEHOLDERS.SELECT": "{{COMMON.PLACEHOLDERS.SELECT}}",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.DETAILS.TITLE": "Details",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SELECTION.TABLES.HEADERS.EMPLOYEE_ID": "Employee ID",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SELECTION.TABLES.HEADERS.NAME": "{{COMMON.LABELS.NAME}}",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SELECTION.TABLES.SELECTED.TITLE": "Selected",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SELECTION.TABLES.UNSELECTED.TITLE": "Unselected",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SELECTION.TITLE": "Selection",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.ACTIONS.APPLY_WITHOUT_SAVING": "Apply without saving",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.ACTIONS.USE_WITHOUT_SAVING": "Use without saving",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.ACTIONS.SAVE_AND_APPLY": "Save & Apply",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.ACTIONS.SAVE_AND_USE": "Save & Use",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.LABELS.EMPLOYEE_TYPE": "Employee type",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.LABELS.FILTERING": "Filtering",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.LABELS.PEOPLE_ADDED": "People added",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.LABELS.PEOPLE_REMOVED": "People removed",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.LABELS.PUBLIC": "Public",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.LABELS.SELECTED": "Selected",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.LABELS.SETS_SELECTED": "Sets selected",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.LABELS.SUBSET_NAME": "Subset name",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.LABELS.UNSELECTED": "Unselected",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.MESSAGES.APPLY.SUCCESS": "Subset applied successfully",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.MESSAGES.EXECUTE.SUCCESS": "Subset has been executed successfully",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.MESSAGES.SAVE.SUCCESS": "Subset saved successfully",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.TEXT.FILTERS": "filters",
  "SUBSET_TOOL.SUBSET_MANAGER.STEPS.SUMMARY.TITLE": "Summary",
  "SUBSET_TOOL.SUBSETS.MESSAGES.APPLY.SUCCESS": "Subset applied successfully",
  "SUBSET_TOOL.SUBSETS.MESSAGES.DELETE.SUCCESS": "Subset was successfully deleted",
  "SUBSET_TOOL.SUBSETS.MESSAGES.DUPLICATE.SUCCESS": "Subset was successfully duplicated",
  "SUBSET_TOOL.SUBSETS.MESSAGES.EXECUTE.SUCCESS": "Subset executed successfully",
  "SUBSET_TOOL.SUBSETS.MESSAGES.SAVE.SUCCESS": "Subset saved successfully",
  "SUBSET_TOOL.SUBSETS.TABLE.HEADERS.SUBSET_NAME": "Subset name",
  "SUBSET_TOOL.SUBSETS.TABLE.HEADERS.SELECTED_SETS": "Selected sets",
  "SUBSET_TOOL.SUBSETS.TABLE.HEADERS.MANAGER_LEVELS": "Manager levels",
  "SUBSET_TOOL.SUBSETS.TABLE.HEADERS.FILTERED": "Filtered",
  "SUBSET_TOOL.SUBSETS.TABLE.HEADERS.MODIFIED": "Modified",
  "SUBSET_TOOL.SUBSETS.TABLE.HEADERS.PUBLIC": "Public",
  "SUBSET_TOOL.SUBSETS.TABLE.HEADERS.CREATED_BY": "Created by",
  "SUBSET_TOOL.SUBSETS.TABLE.TITLE": "Saved subsets",
  "SUBSET_TOOL.TABLE.TITLE": "Saved subsets",

  "TAX_DOCS.ACTIONS.DOWNLOAD": "Download",
  "TAX_DOCS.ACTIONS.DOWNLOAD_ALL": "Download All",
  "TAX_DOCS.MODULE_PAGE_TITLE": "Tax Documents",
  "TAX_DOCS.TABLE.CAPTION": "Tax Documents",
  "TAX_DOCS.TABLE.HEADERS.NAME": "Document date",

  "VALIDATION.CHECKBOXES": "Field is required, select at least one option",
  "VALIDATION.MAXLENGTH": "Provided value is too long",
  "VALIDATION.OPEN_ENDED": "You are only able to enter an open ended absence for absence type of sick",
  "VALIDATION.FILE_SIZE": "Max file size is {{maxFileSize}}Mb",
  "VALIDATION.FILE_TYPE": "Invalid file type",
  "VALIDATION.REQUIRED": "Field is required",
  "VALIDATION.TIME_AFTER": "Time must be after {{minTime}}",
  "VALIDATION.TIME_BETWEEN": "Time must be within your workpattern ({{minDate}} - {{maxDate}})",
  "VALIDATION.TIME_BEFORE": "Time must be before {{maxTime}}",
  "VALIDATION.UNIQUE_FIELD_GROUP_NAME": "Field group name must be unique",
  "VALIDATION.UNIQUE_RECORD_GROUP_NAME": "Record group name must be unique",
  "VALIDATION.UNIQUE_ROLE_NAME": "Role name must be unique",
  "VALIDATION.UNIQUE_SUBSET_NAME": "Subset name must be unique",

  "VERIFICATIONS.ARIA.BUTTONS.CLOSE_DETAILS_PAGE": "Close verification details page",
  "VERIFICATIONS.DASHBOARD_WIDGET.ACTIONS.VIEW_ALL_APPROVALS": "View all approvals",
  "VERIFICATIONS.DASHBOARD_WIDGET.EMPTY_REQUEST": "You have no verifications to check",
  "VERIFICATIONS.DASHBOARD_WIDGET.TITLE": "Verifications",
  "VERIFICATIONS.DETAILS_PAGE.ABSENCE.LABELS.ABSENCE_START": "Absence start",
  "VERIFICATIONS.DETAILS_PAGE.ABSENCE.LABELS.ABSENCE_END": "Absence end",
  "VERIFICATIONS.DETAILS_PAGE.ABSENCE.LABELS.DAYS": "Days",
  "VERIFICATIONS.DETAILS_PAGE.ABSENCE.LABELS.HOURS": "Hours",
  "VERIFICATIONS.DETAILS_PAGE.ABSENCE.LABELS.MINUTES_LATE": "Minutes late",
  "VERIFICATIONS.DETAILS_PAGE.ABSENCE.LABELS.ABSENCE_REASON": "Absence reason",
  "VERIFICATIONS.DETAILS_PAGE.ABSENCE.LABELS.SICKNESS_REASON": "Sickness reason",
  "VERIFICATIONS.DETAILS_PAGE.ABSENCE.LABELS.DEPARTMENT": "Department",
  "VERIFICATIONS.DETAILS_PAGE.ABSENCE.LABELS.WORK_PATTERN": "Work pattern",
  "VERIFICATIONS.DETAILS_PAGE.ABSENCE.LABELS.NOTES": "Absence Detail",
  "VERIFICATIONS.DETAILS_PAGE.DOCUMENT.LABELS.DATE": "Date",
  "VERIFICATIONS.DETAILS_PAGE.DOCUMENT.LABELS.DESCRIPTION": "Description",
  "VERIFICATIONS.DETAILS_PAGE.DOCUMENT.LABELS.CATEGORY": "Category",
  "VERIFICATIONS.DETAILS_PAGE.DOCUMENT.LABELS.FILE_TYPE": "File type",
  "VERIFICATIONS.DETAILS_PAGE.DOCUMENT.LABELS.NOTES": "Notes",
  "VERIFICATIONS.DETAILS_PAGE.MESSAGE.ACCEPTED.SUCCESS": "Verification accepted successfully",
  "VERIFICATIONS.DETAILS_PAGE.MESSAGE.DECLINED.SUCCESS": "Verification declined successfully",
  "VERIFICATIONS.LABELS.MANAGER_LEVEL": "Manager level",
  "VERIFICATIONS.MODALS.ARIA.BUTTONS.CLOSE_ACCEPT": "Close accept verification modal",
  "VERIFICATIONS.MODALS.ARIA.BUTTONS.CLOSE_DECLINE": "Close decline verification modal",
  "VERIFICATIONS.MODALS.DESCRIPTION.ACCEPT": "Are you sure you want to accept this record?",
  "VERIFICATIONS.MODALS.DESCRIPTION.DECLINE": "Are you sure you want to decline this record?",
  "VERIFICATIONS.MODALS.LABELS.REASON": "Reason",
  "VERIFICATIONS.MODULE_PAGE_TITLE": "Verifications",
  "VERIFICATIONS.TYPE.LABELS.ABSENCE": "Time Off",
  "VERIFICATIONS.TYPE.LABELS.DOCUMENT": "Document",

  "WELCOME_WIDGET.TITLE_MESSAGE": "Hello",
  "WELCOME_WIDGET.TODAY": "Today is"
}
